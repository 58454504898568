'use client'

import * as React from 'react'

export interface useCopyToClipboardProps {
  timeout?: number
}

/**
 * Custom hook to copy text to the clipboard and manage the copied state.
 *
 * @param {useCopyToClipboardProps} props - The properties for the hook.
 * @param {number} [props.timeout=2000] - The duration (in milliseconds) for which the copied state remains true.
 *
 * @returns {Object} - An object containing:
 *  - isCopied: A boolean indicating whether the text has been copied.
 *  - copyToClipboard: A function to copy the provided text to the clipboard.
 *
 * @example
 * const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 3000 });
 *
 * return (
 *   <div>
 *     <button onClick={() => copyToClipboard('Hello, World!')}>Copy Text</button>
 *     {isCopied && <span>Copied!</span>}
 *   </div>
 * );
 */
export function useCopyToClipboard({ timeout = 2000 }: useCopyToClipboardProps) {
  const [isCopied, setIsCopied] = React.useState<Boolean>(false)

  const copyToClipboard = (value: string) => {
    if (typeof window === 'undefined' || !navigator.clipboard?.writeText) {
      return
    }

    if (!value) {
      return
    }

    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true)

      setTimeout(() => {
        setIsCopied(false)
      }, timeout)
    })
  }

  return { isCopied, copyToClipboard }
}
