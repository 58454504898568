import React, { FC, memo } from 'react'
import { Check, Copy, Download } from 'lucide-react'
import { nanoid } from 'nanoid'
import ReactMarkdown, { Options } from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import remarkGfm from 'remark-gfm' // Github flavored markdown
import remarkMath from 'remark-math'

import { useCopyToClipboard } from '@/app/components/hooks/use-copy-to-clipboard'
import { Button } from '@/app/components/ui/button'

// Memoize the ReactMarkdown component to prevent unnecessary re-renders
export const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children && prevProps.className === nextProps.className
)

export default function Markdown({ content }: { content: string }) {
  {
    /* prose class has a max width, so we use max-w-none to remove it */
  }
  return (
    <MemoizedReactMarkdown
      className="prose max-w-none break-words text-sm dark:prose-invert prose-p:leading-relaxed prose-pre:p-0"
      remarkPlugins={[remarkGfm, remarkMath]}
      components={{
        // Use span because of hydration errors with nested p / divs
        p: ({ children }) => <span className="mb-4 block">{children}</span>,
        code: ({ node, className, children, ...props }) => {
          const childArray = React.Children.toArray(children)
          const firstChild = childArray[0] as React.ReactElement
          const firstChildAsString = React.isValidElement(firstChild)
            ? (firstChild as React.ReactElement).props.children
            : firstChild

          if (firstChildAsString === '▍') {
            return <span className="mt-1 animate-pulse cursor-default">▍</span>
          }

          if (typeof firstChildAsString === 'string') {
            childArray[0] = firstChildAsString.replace('`▍`', '▍')
          }

          const match = /language-(\w+)/.exec(className || '')

          if (typeof firstChildAsString === 'string' && !firstChildAsString.includes('\n')) {
            return (
              <code className={className} {...props}>
                {childArray}
              </code>
            )
          }

          return (
            <MessageCodeBlock
              key={nanoid(6)}
              language={(match && match[1]) || ''}
              value={String(childArray).replace(/\n$/, '')}
              {...props}
            />
          )
        },
        // Open links in new tab by default
        a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />
      }}
    >
      {content}
    </MemoizedReactMarkdown>
  )
}

interface MessageCodeBlockProps {
  language: string
  value: string
}

interface languageMap {
  [key: string]: string | undefined
}

export const programmingLanguages: languageMap = {
  javascript: '.js',
  python: '.py',
  java: '.java',
  c: '.c',
  cpp: '.cpp',
  'c++': '.cpp',
  'c#': '.cs',
  ruby: '.rb',
  php: '.php',
  swift: '.swift',
  'objective-c': '.m',
  kotlin: '.kt',
  typescript: '.ts',
  go: '.go',
  perl: '.pl',
  rust: '.rs',
  scala: '.scala',
  haskell: '.hs',
  lua: '.lua',
  shell: '.sh',
  sql: '.sql',
  html: '.html',
  css: '.css'
}

export const MessageCodeBlock: FC<MessageCodeBlockProps> = memo(({ language, value }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })

  const downloadAsFile = () => {
    if (typeof window === 'undefined') {
      return
    }
    const fileExtension = programmingLanguages[language] || '.file'
    const suggestedFileName = `file-${nanoid(5)}${fileExtension}`
    const fileName = window.prompt('Enter file name', suggestedFileName)

    if (!fileName) {
      return
    }

    const blob = new Blob([value], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = fileName
    link.href = url
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const onCopy = () => {
    if (isCopied) return
    copyToClipboard(value)
  }

  return (
    <div className="codeblock relative max-w-full overflow-x-auto overflow-y-auto bg-zinc-950">
      <div className="flex items-center justify-between bg-zinc-700 px-4 text-white">
        <span className="text-xs lowercase">{language}</span>
        <div className="flex items-center space-x-1">
          <Button
            variant="ghost"
            size="icon"
            className="hover:bg-zinc-800 focus-visible:ring-1 focus-visible:ring-slate-700 focus-visible:ring-offset-0"
            onClick={downloadAsFile}
          >
            <Download size={16} />
          </Button>

          <Button
            variant="ghost"
            size="icon"
            className="text-xs hover:bg-zinc-800 focus-visible:ring-1 focus-visible:ring-slate-700 focus-visible:ring-offset-0"
            onClick={onCopy}
          >
            {isCopied ? <Check size={16} /> : <Copy size={16} />}
          </Button>
        </div>
      </div>
      <SyntaxHighlighter
        language={language}
        style={oneDark}
        customStyle={{
          maxHeight: '500px',
          margin: 0,
          background: 'transparent'
        }}
        codeTagProps={{
          style: {
            fontFamily: 'var(--font-mono)'
          }
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  )
})
MessageCodeBlock.displayName = 'MessageCodeBlock'
