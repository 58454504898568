import { Copy } from 'lucide-react'

import { Button } from '@/app/components/ui/button'
import { toast } from '@/app/components/ui/use-toast'
import { logger } from '@/app/lib/logging'

/**
 * Handles errors by logging them and displaying a toast notification.
 * @param {unknown} error - The error object.
 * @param {string} context - The context in which the error occurred.
 */
export function handleError(error: unknown, context: string) {
  logger.error(`Error in ${context}:`, error)

  const errorMessage = error instanceof Error ? error.message : String(error)
  const errorStack = error instanceof Error ? error.stack : 'No stack trace available'

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast({ title: 'Error details copied', duration: 2000 }))
  }

  if (process.env.NODE_ENV === 'development') {
    toast({
      title: `Error in ${context}`,
      description: (
        <div className="space-y-2">
          <div className="font-medium">Details:</div>
          <div className="overflow-auto whitespace-pre-wrap rounded bg-secondary/50 p-2 text-sm">
            {errorMessage}
          </div>
          <div className="font-medium">Stack:</div>
          <div className="max-h-48 w-full overflow-auto rounded bg-secondary/50 p-2">
            <pre className="text-sm">
              <code className="whitespace-pre">{errorStack}</code>
            </pre>
          </div>
          <Button
            variant="secondary"
            size="sm"
            className="mt-2 text-primary hover:bg-secondary/80"
            onClick={() =>
              copyToClipboard(`Error in ${context}:\n${errorMessage}\n\nStack:\n${errorStack}`)
            }
          >
            <Copy className="mr-2 h-4 w-4" />
            Copy Error Details
          </Button>
        </div>
      ),
      variant: 'destructive',
      duration: 20000
    })
  } else {
    toast({
      title: 'Oops! Something went wrong.',
      description: `We've been notified and will fix it ASAP.`,
      variant: 'destructive'
    })
  }
}
