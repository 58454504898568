import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/nextjs'
import { LogIn } from 'lucide-react'

import { NexusState } from '@/app/lib/types'

export function UserProfile({ nexusState }: { nexusState: NexusState }) {
  const { isUserLoaded, user } = nexusState

  if (!isUserLoaded) {
    return <div className="flex gap-2"></div>
  }

  return (
    <div className="flex gap-2 text-base-content">
      <SignedIn>
        <UserButton
          appearance={{
            elements: {
              userButtonBox: 'text-base-content'
            }
          }}
        />
      </SignedIn>
      <SignedOut>
        <SignInButton mode="modal">
          <button className="btn">
            <LogIn className="mr-2 h-5 w-5" />
            <span className="hidden sm:inline">Login / SignUp</span>
            <span className="sm:hidden">Sign in</span>
          </button>
        </SignInButton>
      </SignedOut>
    </div>
  )
}
