import Image from 'next/image'
import { FileIcon, FileText, Film, Headphones } from 'lucide-react'

import { Attachment } from '@/app/lib/types'

interface AttachmentIconProps {
  attachment: Attachment
  width: number
  height: number
}

export function AttachmentIcon({ attachment, width, height }: AttachmentIconProps) {
  const fileType = attachment.mimeType || ''

  // Special handling for PDFs
  if (fileType === 'application/pdf') {
    return (
      <div
        className="flex items-center justify-center rounded bg-gray-100 dark:bg-gray-800"
        style={{ width, height }}
      >
        <Image
          src="/img/pdf-icon.svg"
          alt="PDF file"
          width={width}
          height={height}
          className="text-gray-600 dark:text-gray-300"
        />
      </div>
    )
  }

  // Handle images
  if (attachment.isImage) {
    const imageUrl = `${attachment.cdnUrl}-/smart_resize/${width}x${height}/`
    return (
      <img
        src={imageUrl}
        alt={attachment.name}
        width={width}
        height={height}
        className="rounded object-cover"
      />
    )
  }

  // Handle other file types
  let IconComponent: React.ComponentType<{ className?: string; style?: React.CSSProperties }> =
    FileIcon

  // Handle other file types
  if (fileType.startsWith('audio/')) {
    IconComponent = Headphones
  } else if (fileType.startsWith('video/')) {
    IconComponent = Film
  } else if (fileType.startsWith('text/')) {
    IconComponent = FileText
  }

  return (
    <div
      className="flex items-center justify-center rounded bg-gray-100 dark:bg-gray-800"
      style={{ width, height }}
    >
      <IconComponent className="text-gray-600 dark:text-gray-300" />
    </div>
  )
}
