'use client'

import { useCallback, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import {
  ChevronDown,
  ChevronUp,
  Edit,
  Loader2,
  MessageSquare,
  MessageSquarePlus,
  MoreVertical,
  Trash2
} from 'lucide-react'
import { nanoid } from 'nanoid'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/app/components/ui/alert-dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/app/components/ui/dropdown-menu'
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  useSidebar
} from '@/app/components/ui/sidebar'
import ThemeImage from '@/app/components/ui/theme-image'
import { useConversations } from '@/app/context/ConversationContext'
import { logger } from '@/app/lib/logging'
import { NexusState } from '@/app/lib/types'
import { cn } from '@/app/lib/utils'
import {
  getConversation,
  getConversations,
  removeConversation,
  renameConversation
} from '@/app/si/conversations'
import { Button } from './ui/button'
import { useToast } from './ui/use-toast'

export function OurSidebar({ nexusState }: { nexusState: NexusState }) {
  const {
    anonymousUserId,
    setMessages,
    setConversationId,
    setInput,
    setConciergeData,
    setConciergeStatus,
    conversationId: currentConversationId,
    isSignedIn,
    isUserLoaded,
    handleNewConversation
  } = nexusState

  const [isLoadingConversations, setIsLoadingConversations] = useState(true)

  // Use the ConversationContext
  const { conversations, setConversations } = useConversations()

  const fetchConversations = useCallback(async () => {
    setIsLoadingConversations(true)
    try {
      const fetchedConversations = await getConversations(
        isSignedIn ? undefined : anonymousUserId || undefined
      )
      setConversations(fetchedConversations)
      logger.debug('Conversations fetched successfully', {
        count: fetchedConversations.length
      })
    } catch (error) {
      logger.error('Error fetching conversations:', error)
    } finally {
      setIsLoadingConversations(false)
    }
  }, [isSignedIn, anonymousUserId, setConversations])

  useEffect(() => {
    if (isUserLoaded) {
      fetchConversations()
    }
  }, [fetchConversations, isUserLoaded])

  const [showAllConversations, setShowAllConversations] = useState(false)
  const { toast } = useToast()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null)

  const [isRenaming, setIsRenaming] = useState(false)
  const [newTitle, setNewTitle] = useState('')
  const [renamingConversationId, setRenamingConversationId] = useState<string | null>(null)

  const { setOpen, setOpenMobile } = useSidebar()

  const closeSidebar = () => {
    // Close both mobile and desktop sidebars
    setOpen(false)
    setOpenMobile(false)
  }

  const handleConversationClick = async (conversationId: string) => {
    try {
      const conversation = await getConversation(
        conversationId,
        isSignedIn ? undefined : anonymousUserId || undefined
      )
      if (conversation) {
        setMessages(conversation.messages)
        setConversationId(conversationId)
        setInput('')
        setConciergeData(null)
        setConciergeStatus(null)
      }
    } catch (error) {
      logger.error('Error loading conversation', error)
      toast({
        title: 'Error loading conversation',
        description: 'Unable to load the selected conversation. Please try again.',
        duration: 5000
      })
    }
  }

  const handleRemoveConversation = (conversationId: string) => {
    setPendingDeleteId(conversationId)
    setDialogOpen(true)
  }

  const confirmDelete = async () => {
    if (!pendingDeleteId) return

    try {
      const result = await removeConversation(
        pendingDeleteId,
        isSignedIn ? undefined : anonymousUserId || undefined
      )
      if (result.success) {
        setConversations(prevConversations =>
          prevConversations.filter(conv => conv.id !== pendingDeleteId)
        )
        toast({
          title: 'Conversation deleted',
          description: 'The conversation has been successfully deleted.',
          duration: 3000
        })

        // Clear the chat if the deleted conversation is the current one
        if (pendingDeleteId === currentConversationId) {
          setMessages([])
          setConversationId(nanoid(12))
          setInput('')
          setConciergeData(null)
          setConciergeStatus(null)
        }
      } else {
        throw new Error(result.message || 'Failed to delete conversation')
      }
    } catch (error) {
      logger.error('Error deleting conversation', error)
      toast({
        title: 'Error deleting conversation',
        description:
          error instanceof Error
            ? error.message
            : 'Unable to delete the conversation. Please try again.',
        duration: 5000
      })
    } finally {
      setDialogOpen(false)
      setPendingDeleteId(null)
    }
  }

  const handleRename = (conversationId: string) => {
    const conversation = conversations?.find(c => c.id === conversationId)
    if (conversation) {
      setNewTitle(conversation.title)
      setRenamingConversationId(conversationId)
      setIsRenaming(true)
    }
  }

  const confirmRename = async () => {
    if (!renamingConversationId || !newTitle.trim()) {
      toast({
        title: 'Error',
        description: 'Conversation title cannot be empty.',
        duration: 3000
      })
      return
    }

    try {
      const result = await renameConversation(
        renamingConversationId,
        newTitle.trim(),
        anonymousUserId || undefined
      )
      if (result.success) {
        setConversations(
          prevConversations =>
            prevConversations?.map(conv =>
              conv.id === renamingConversationId ? { ...conv, title: newTitle.trim() } : conv
            ) || null
        )
        toast({
          title: 'Success',
          description: 'Conversation renamed successfully.',
          duration: 3000
        })
      } else {
        throw new Error(result.message || 'Failed to rename conversation')
      }
    } catch (error) {
      logger.error('Error renaming conversation', error)
      toast({
        title: 'Error renaming conversation',
        description: error instanceof Error ? error.message : 'An unexpected error occurred.',
        duration: 5000
      })
    } finally {
      setIsRenaming(false)
      setNewTitle('')
      setRenamingConversationId(null)
    }
  }

  const displayedConversations = conversations
    ? showAllConversations
      ? conversations
      : conversations.slice(0, 8)
    : []

  const conversationVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
        delay: index * 0.05
      }
    })
  }

  return (
    <Sidebar className="bg-background dark:bg-dark-base">
      <SidebarHeader>
        <div id="sidebar-logo" className="flex h-16 items-center justify-center">
          <ThemeImage
            lightSrc="/img/cora-logo-text.svg"
            darkSrc="/img/cora-logo-text-white.svg"
            alt="Cora Logo"
            width={109}
            height={28}
          />
        </div>
      </SidebarHeader>
      <SidebarContent>
        <div className="flex-1 p-2 sm:p-4">
          <div>
            <Button
              variant="ghost"
              className="btn mb-4 w-full justify-center text-center font-medium"
              onClick={handleNewConversation}
            >
              <MessageSquarePlus className="mr-2 h-5 w-5" />
              New Conversation
            </Button>
            {isLoadingConversations ? (
              <div className="flex items-center space-x-2 px-3 py-2 text-xs text-base-content/70">
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Loading conversations...</span>
              </div>
            ) : conversations && conversations.length > 0 ? (
              <>
                <AnimatePresence>
                  <ul className="space-y-2">
                    {displayedConversations.map((conversation, index) => (
                      <motion.li
                        key={conversation.id}
                        custom={index}
                        variants={conversationVariants}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.05 }}
                        className={cn(
                          'relative rounded-lg px-3 py-2 text-xs transition-colors',
                          conversation.id === currentConversationId
                            ? 'bg-primary/90 text-primary-content'
                            : 'bg-base-100 hover:bg-base-200',
                          index !== displayedConversations.length - 1 &&
                            'border-b border-base-300'
                        )}
                      >
                        <div
                          onClick={() => handleConversationClick(conversation.id)}
                          className="sidebar-conversation flex cursor-pointer items-center justify-between"
                        >
                          <div className="flex items-center space-x-2">
                            <MessageSquare
                              className={cn(
                                'h-4 w-4 flex-shrink-0',
                                conversation.id === currentConversationId
                                  ? 'text-primary-content'
                                  : 'text-base-content/50'
                              )}
                            />
                            <span
                              className={cn(
                                'font-medium',
                                conversation.id === currentConversationId
                                  ? 'font-semibold text-primary-content dark:text-white'
                                  : 'text-base-content/70 dark:text-white/70'
                              )}
                            >
                              {conversation.title}
                            </span>
                          </div>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost" size="icon" className="h-6 w-6">
                                <MoreVertical className="h-3 w-3" />
                                <span className="sr-only">Open menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                              align="end"
                              side="right"
                              className="w-56 bg-base-100 text-base-content"
                            >
                              <DropdownMenuItem
                                onClick={() => handleRename(conversation.id)}
                                className="cursor-pointer hover:bg-base-200"
                              >
                                <Edit className="mr-2 h-4 w-4" />
                                <span>Rename</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => handleRemoveConversation(conversation.id)}
                                className="group cursor-pointer hover:bg-base-200"
                              >
                                <Trash2 className="mr-2 h-4 w-4 transition-colors group-hover:text-red-500" />
                                <span className="transition-colors group-hover:text-red-500">
                                  Delete
                                </span>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </motion.li>
                    ))}
                  </ul>
                </AnimatePresence>
                {conversations.length > 8 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    className="btn btn-ghost btn-sm mt-2 w-full justify-start"
                    onClick={() => setShowAllConversations(!showAllConversations)}
                  >
                    {showAllConversations ? (
                      <>
                        <ChevronUp className="mr-2 h-4 w-4" />
                        Show Less
                      </>
                    ) : (
                      <>
                        <ChevronDown className="mr-2 h-4 w-4" />
                        Show More
                      </>
                    )}
                  </Button>
                )}
              </>
            ) : (
              <p className="px-3 py-2 text-xs text-base-content/70">No conversations yet.</p>
            )}
          </div>
        </div>
      </SidebarContent>
      <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the conversation.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={confirmDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {isRenaming && (
        <AlertDialog open={isRenaming} onOpenChange={setIsRenaming}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Rename Conversation</AlertDialogTitle>
            </AlertDialogHeader>
            <input
              type="text"
              value={newTitle}
              onChange={e => setNewTitle(e.target.value)}
              className="input input-bordered w-full"
              placeholder="New title"
            />
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  setIsRenaming(false)
                  setNewTitle('')
                  setRenamingConversationId(null)
                }}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction onClick={confirmRename}>Rename</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </Sidebar>
  )
}
