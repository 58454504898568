import { useEffect, useState } from 'react'
import Image from 'next/image'
import { Message, ToolInvocation } from 'ai'
import {
  Brain,
  Calculator,
  ChevronDown,
  ChevronUp,
  Compass,
  Copy,
  ExternalLink,
  Globe,
  HelpCircle,
  Lightbulb,
  Loader2,
  MapPin,
  RefreshCw,
  Search,
  Settings,
  Sparkles,
  Target,
  User2,
  Wrench
} from 'lucide-react'

import { useCopyToClipboard } from '@/app/components/hooks/use-copy-to-clipboard'
import Markdown from '@/app/components/markdown'
import { Avatar, AvatarFallback, AvatarImage } from '@/app/components/ui/avatar'
import { Button } from '@/app/components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/app/components/ui/tooltip'
import { Attachment, ConciergeData, NexusState } from '@/app/lib/types'

// Add this function near the top of the file, after the imports
const displayToolName = (toolName: string): string => {
  const toolNameMap: { [key: string]: string } = {
    Tavily: 'Web Research',
    Calculator: 'Calculations'
    // Add more mappings as needed
  }
  return toolNameMap[toolName] || toolName
}

const getToolStatusMessage = (toolName: string, args: any): string => {
  switch (toolName) {
    case 'Tavily':
      if (args?.query) {
        return `Researching "${args.query}"...`
      } else {
        return 'Gathering information...'
      }
    case 'Calculator':
      return `Computing ${args?.expression || 'result'}...`
    default:
      return 'Working on this...'
  }
}

const generateLuckyLink = (query: string) => {
  return {
    title: query,
    // On DuckDuckGo, the search query is prepended with a backslash for "I'm feeling lucky"
    url: `https://duckduckgo.com/?q=${encodeURIComponent('\\' + query)}`
  }
}

// Main message components
export const UserMessage: React.FC<{
  message: Message
  nexusState: NexusState
}> = ({ message, nexusState }) => {
  const { user } = nexusState
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })

  let attachments: Attachment[] = []
  if (message.experimental_attachments && Array.isArray(message.experimental_attachments)) {
    attachments = message.experimental_attachments.map((att: any) => ({
      uuid: att.uuid,
      name: att.name,
      size: att.size,
      mimeType: att.mimeType,
      cdnUrl: att.cdnUrl,
      isImage: att.isImage,
      userId: att.userId
    }))
  }

  return (
    <div
      data-testid="user-message"
      className="user-message flex items-start justify-end gap-3 px-3 pt-4"
    >
      <div className="relative max-w-[95%] rounded-lg bg-blue-100 p-3 dark:bg-blue-900 md:max-w-[90%] md:p-4">
        <div className="prose prose-sm max-w-none text-blue-800 dark:prose-invert dark:text-blue-200">
          {message.content}
        </div>

        <div className="mt-3 flex justify-end">
          <Button
            size="sm"
            variant="ghost"
            onClick={() => copyToClipboard(message.content)}
            className="text-xs text-zinc-500/70 transition-colors hover:bg-transparent hover:text-zinc-600 dark:text-zinc-400/70 dark:hover:text-zinc-300"
          >
            {isCopied ? (
              <>
                <Copy className="mr-1 h-3 w-3" />
                Copied to clipboard
              </>
            ) : (
              <>
                <Copy className="mr-1 h-3 w-3" />
                Copy
              </>
            )}
          </Button>
        </div>

        {attachments.length > 0 && (
          <div className="mt-3 border-t border-blue-200 pt-3 dark:border-blue-800">
            <h4 className="mb-2 text-xs font-medium text-blue-700 dark:text-blue-300">
              Attachments
            </h4>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
              {attachments.map((attachment, index) => (
                <div
                  key={index}
                  className="flex items-center overflow-hidden rounded-lg bg-blue-50 p-2 dark:bg-blue-950"
                >
                  <span className="truncate text-xs text-blue-700 dark:text-blue-300">
                    {attachment.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Avatar className="h-8 w-8 md:h-10 md:w-10">
        {user && user.imageUrl ? (
          <AvatarImage src={user.imageUrl} alt={user.fullName || 'You'} />
        ) : (
          <AvatarFallback>
            <User2 className="h-6 w-6" />
          </AvatarFallback>
        )}
      </Avatar>
    </div>
  )
}

export const AIMessage: React.FC<{
  message: Message
  nexusState: NexusState
}> = ({ message, nexusState }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })
  const { isLoading, reload } = nexusState

  return (
    <div
      data-testid="ai-message"
      className="ai-message flex items-start justify-start gap-3 px-3 pt-4"
    >
      <Avatar className="h-8 w-8 md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-purple.svg" alt="Cora" />
        <AvatarFallback>C</AvatarFallback>
      </Avatar>
      <div className="relative max-w-[95%] rounded-lg bg-purple-50 p-3 dark:bg-purple-900/20 md:max-w-[90%] md:p-4">
        <div className="prose prose-sm max-w-none dark:prose-invert">
          <Markdown content={message.content} />
        </div>

        {!isLoading && (
          <div className="mt-3 flex justify-start gap-2">
            <Button
              size="sm"
              variant="ghost"
              onClick={() => copyToClipboard(message.content)}
              className="text-xs text-zinc-500/70 transition-colors hover:bg-transparent hover:text-zinc-600 dark:text-zinc-400/70 dark:hover:text-zinc-300"
            >
              {isCopied ? (
                <>
                  <Copy className="mr-1 h-3 w-3" />
                  Copied to clipboard
                </>
              ) : (
                <>
                  <Copy className="mr-1 h-3 w-3" />
                  Copy
                </>
              )}
            </Button>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => reload()}
              className="text-xs text-zinc-500/70 transition-colors hover:bg-transparent hover:text-zinc-600 dark:text-zinc-400/70 dark:hover:text-zinc-300"
            >
              <RefreshCw className="mr-1 h-3 w-3" />
              Regenerate
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const ToolInvocationDisplay: React.FC<{
  toolInvocation: ToolInvocation
  nexusState: NexusState
}> = ({ toolInvocation, nexusState }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const isDeveloper = nexusState.user?.publicMetadata?.developer === true

  const getToolIcon = (toolName: string) => {
    switch (toolName) {
      case 'Tavily':
        return <Search className="h-4 w-4" />
      case 'Calculator':
        return <Calculator className="h-4 w-4" />
      default:
        return <Wrench className="h-4 w-4" />
    }
  }

  return (
    <div className="border-primary-200 dark:border-primary-700 bg-primary-100 dark:bg-primary-900 rounded-md border p-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          {getToolIcon(toolInvocation.toolName)}
          <span className="text-primary-700 dark:text-primary-300 font-medium">
            {displayToolName(toolInvocation.toolName)}
          </span>
        </div>
        {isDeveloper && (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-primary-700 dark:text-primary-300"
          >
            {isExpanded ? 'Show less' : 'Show more'}
            <ChevronDown
              className={`ml-1 h-4 w-4 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
            />
          </Button>
        )}
      </div>
      <div className="mt-2">
        {(toolInvocation.state === 'partial-call' || toolInvocation.state === 'call') && (
          <div className="text-primary-600 dark:text-primary-400 text-sm">
            <Loader2 className="mr-2 inline h-4 w-4 animate-spin" />
            {isDeveloper
              ? getToolStatusMessage(toolInvocation.toolName, toolInvocation.args)
              : 'I am working on this...'}
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="mt-2 space-y-2">
          <div className="text-sm">
            <strong>Arguments:</strong>
            <pre className="bg-primary-50 dark:bg-primary-800 mt-1 rounded p-2 text-xs">
              {JSON.stringify(toolInvocation.args, null, 2)}
            </pre>
          </div>
          {toolInvocation.state === 'result' && (
            <div className="text-sm">
              <strong>Result:</strong>
              <pre className="bg-primary-50 dark:bg-primary-800 mt-1 rounded p-2 text-xs">
                {JSON.stringify(toolInvocation.result, null, 2)}
              </pre>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export const ConciergeMessage: React.FC<{
  message: Message
  conciergeData: ConciergeData | null
  originalQuery: string
  nexusState?: NexusState
}> = ({ message, conciergeData, originalQuery, nexusState }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)

  if (!conciergeData) return null

  const isAutoSelection = nexusState?.requestedModel === ''

  return (
    <div
      data-testid="concierge-message"
      className="concierge-message flex items-start justify-start gap-3 px-3 pt-4"
    >
      <Avatar className="h-8 w-8 md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-gray.svg" alt="Cora Concierge" />
        <AvatarFallback>C</AvatarFallback>
      </Avatar>
      <div className="relative max-w-[95%] rounded-lg bg-purple-50 p-3 text-sm dark:bg-purple-900/20 md:max-w-[90%] md:p-4">
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-sm font-medium text-purple-600 dark:text-purple-400">
            <Sparkles className="mr-2 inline-block h-4 w-4" />
            Concierge Insights from Cora
          </h4>
          <TooltipProvider delayDuration={100}>
            <Tooltip>
              <TooltipTrigger>
                <span className="text-2xl">{conciergeData.moodEmoji}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>
                  I sense your mood as <i>{conciergeData.mood}</i>
                </p>
                <p>This helps me personalize my response for you.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <h5 className="text-sm font-medium text-purple-600 dark:text-purple-400">
                Enhanced Request:
              </h5>
              <TooltipProvider delayDuration={100}>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="h-4 w-4 text-purple-500 dark:text-purple-400" />
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <p>I enhance your request by:</p>
                    <ul className="mt-2 list-disc pl-4">
                      <li>Understanding your core needs</li>
                      <li>Adding helpful context</li>
                      <li>Including relevant expertise</li>
                    </ul>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => copyToClipboard(conciergeData.enhancedRequest)}
              className="text-xs text-zinc-500/70 transition-colors hover:bg-transparent hover:text-zinc-600 dark:text-zinc-400/70 dark:hover:text-zinc-300"
            >
              {isCopied ? (
                <>
                  <Copy className="mr-1 h-3 w-3" />
                  Copied to clipboard
                </>
              ) : (
                <>
                  <Copy className="mr-1 h-3 w-3" />
                  Copy
                </>
              )}
            </Button>
          </div>
          <div className="rounded-md bg-blue-100 p-3 dark:bg-blue-900">
            <div className="prose prose-sm max-w-none italic dark:prose-invert">
              <Markdown content={conciergeData.enhancedRequest} />
            </div>
          </div>
        </div>

        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsDetailsExpanded(!isDetailsExpanded)}
          className="mt-4 w-full justify-start text-sm"
        >
          <ChevronDown
            className={`mr-2 h-4 w-4 transition-transform ${isDetailsExpanded ? 'rotate-180' : ''}`}
          />
          <span>{isDetailsExpanded ? 'Show less' : 'Show more about my approach'}</span>
        </Button>

        {isDetailsExpanded && (
          <div className="mt-4 space-y-6 rounded-lg bg-gradient-to-b from-white/50 to-purple-50/30 p-6 dark:from-black/20 dark:to-purple-900/10">
            {/* Understanding Section */}
            <div className="space-y-3">
              <h5 className="flex items-center gap-2 text-sm font-medium text-purple-700 dark:text-purple-300">
                <Brain className="h-4 w-4" />
                <span>My Understanding</span>
              </h5>
              <p className="text-sm leading-relaxed text-gray-700 dark:text-gray-300">
                {conciergeData.reasoning}
              </p>
            </div>

            {/* Approach Section */}
            <div className="space-y-3">
              <h5 className="flex items-center gap-2 text-sm font-medium text-purple-700 dark:text-purple-300">
                <Compass className="h-4 w-4" />
                <span>My Approach</span>
              </h5>
              <div className="grid gap-3 sm:grid-cols-2">
                <div className="rounded-lg bg-white/70 p-3 shadow-sm transition-shadow hover:shadow-md dark:bg-black/20">
                  <div className="mb-1 text-xs font-medium text-purple-600/70 dark:text-purple-400/70">
                    How I will Process This
                  </div>
                  <div className="flex items-center gap-2">
                    <Target className="h-4 w-4 text-purple-600 dark:text-purple-400" />
                    <span className="text-sm text-purple-700 dark:text-purple-300">
                      {conciergeData.responseStrategy === 'fast' && 'Quick and Precise'}
                      {conciergeData.responseStrategy === 'reasoning' && 'Thoughtful Analysis'}
                      {conciergeData.responseStrategy === 'eloquent' &&
                        'Articulate Expression'}
                      {conciergeData.responseStrategy === 'creative' && 'Creative Exploration'}
                    </span>
                  </div>
                </div>
                <div className="rounded-lg bg-white/70 p-3 shadow-sm transition-shadow hover:shadow-md dark:bg-black/20">
                  <div className="mb-1 text-xs font-medium text-purple-600/70 dark:text-purple-400/70">
                    How I Will Present It
                  </div>
                  <div className="flex items-center gap-2">
                    <Sparkles className="h-4 w-4 text-purple-600 dark:text-purple-400" />
                    <span className="text-sm text-purple-700 dark:text-purple-300">
                      {conciergeData.outputFormat === 'prose' && 'Flowing Narrative'}
                      {conciergeData.outputFormat === 'structured' && 'Clear Structure'}
                      {conciergeData.outputFormat === 'concise' && 'Brief and Direct'}
                      {conciergeData.outputFormat === 'hybrid' && 'Balanced Approach'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Additional Context Section */}
            <div className="flex flex-wrap gap-2">
              {conciergeData.requiresWebSearch && (
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="group flex items-center gap-2 rounded-full bg-gradient-to-r from-blue-50 to-blue-100 px-3 py-1.5 text-sm text-blue-700 shadow-sm transition-all hover:shadow-md dark:from-blue-900/40 dark:to-blue-800/40 dark:text-blue-300">
                        <Globe className="h-3.5 w-3.5" />
                        <span>Including Latest Information</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      I will consult current sources to provide the most up-to-date information
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {conciergeData.requiresLocation && (
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="group flex items-center gap-2 rounded-full bg-gradient-to-r from-green-50 to-green-100 px-3 py-1.5 text-sm text-green-700 shadow-sm transition-all hover:shadow-md dark:from-green-900/40 dark:to-green-800/40 dark:text-green-300">
                        <MapPin className="h-3.5 w-3.5" />
                        <span>Location Relevant</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      I will consider your location to provide more relevant insights
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
          </div>
        )}

        {isAutoSelection && conciergeData?.humanProvider && conciergeData?.humanModel && (
          <div className="absolute bottom-2 right-2 flex items-center text-xs text-gray-500 dark:text-gray-400">
            <span>
              {conciergeData.humanProvider} {conciergeData.humanModel}
            </span>
            {conciergeData.providerLogo && (
              <Image
                src={conciergeData.providerLogo}
                alt={`${conciergeData.humanProvider} logo`}
                className="ml-1 inline-block h-4 w-4"
                width={16}
                height={16}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const FollowupMessage: React.FC<{
  message: Message
  setInput: (input: string) => void
}> = ({ message, setInput }) => {
  const followupData = message.data as {
    mood_analysis?: string[]
    search_queries?: string[]
    fun_fact?: string
    follow_up_questions?: string[]
  }

  const [isExpanded, setIsExpanded] = useState(true)

  useEffect(() => {
    const savedPreference = localStorage.getItem('followupExpanded')
    if (savedPreference !== null) {
      setIsExpanded(savedPreference === 'true')
    }
  }, [])

  const toggleExpanded = () => {
    const newValue = !isExpanded
    setIsExpanded(newValue)
    localStorage.setItem('followupExpanded', String(newValue))
  }

  // If there's no data other than follow-up questions, don't show the message at all
  if (!followupData.fun_fact && !followupData.search_queries?.length) {
    return null
  }

  return (
    <div
      data-testid="followup-message"
      className="followup-message flex items-start justify-start gap-2 px-2 pt-4 md:gap-4 md:px-5"
    >
      <Avatar className="hidden h-8 w-8 md:block md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-gray.svg" alt="Cora Followup" />
        <AvatarFallback>C</AvatarFallback>
      </Avatar>
      {isExpanded ? (
        <div className="relative max-w-[95%] space-y-6 rounded-lg bg-gray-100 p-4 text-sm dark:bg-gray-800 md:max-w-[80%]">
          <div className="relative pr-8">
            {followupData.fun_fact && (
              <div data-testid="followup-fun-fact" className="fun-fact mb-8">
                <h4 className="text-primary-600 dark:text-primary-400 mb-3 flex items-center text-sm font-medium">
                  <Lightbulb className="mr-2 h-4 w-4" />
                  Fun Fact
                </h4>
                <p className="text-gray-700 dark:text-gray-300">{followupData.fun_fact}</p>
              </div>
            )}

            {followupData.search_queries && followupData.search_queries.length > 0 && (
              <div data-testid="followup-search-queries" className="search-queries mb-8">
                <h4 className="text-primary-600 dark:text-primary-400 mb-3 flex items-center text-sm font-medium">
                  <Globe className="mr-2 h-4 w-4" />
                  Related Web Sites
                </h4>
                <div className="grid gap-3 sm:grid-cols-2">
                  {followupData.search_queries.map((query, index) => {
                    const luckyLink = generateLuckyLink(query)
                    return (
                      <a
                        key={index}
                        href={luckyLink.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary-600 hover:bg-primary-100 dark:text-primary-300 dark:hover:bg-primary-700 flex min-w-0 items-center justify-between rounded-lg bg-white p-2 text-sm font-medium transition-all duration-200 hover:shadow-md dark:bg-gray-700"
                      >
                        <span className="min-w-0 flex-1 truncate pr-2">{luckyLink.title}</span>
                        <ExternalLink className="h-4 w-4 flex-shrink-0" />
                      </a>
                    )
                  })}
                </div>
              </div>
            )}

            <Button
              data-testid="followup-collapse-button"
              variant="ghost"
              onClick={toggleExpanded}
              className="absolute right-0 top-0 flex h-6 items-center opacity-50 transition-opacity hover:opacity-100"
              aria-label="Hide follow-ups"
            >
              <ChevronUp className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ) : (
        <div
          onClick={toggleExpanded}
          className="flex cursor-pointer items-center gap-1 text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          Show additional information {followupData.fun_fact ? 'and fun facts' : ''}
          <ChevronDown className="h-3 w-3 transition-transform group-hover:translate-y-0.5" />
        </div>
      )}
    </div>
  )
}

export const ToolMessage: React.FC<{
  message: Message
  nexusState: NexusState
}> = ({ message, nexusState }) => {
  return (
    <div className="tool-message flex items-start justify-start gap-2 px-2 pt-4 md:gap-4 md:px-5">
      <Avatar className="hidden h-8 w-8 md:block md:h-10 md:w-10">
        <AvatarImage src="/img/bubbletar-cora-gray.svg" alt="Tool" />
        <AvatarFallback>T</AvatarFallback>
      </Avatar>
      <div className="relative w-[95%] space-y-3 rounded-lg p-3 text-sm md:max-w-[80%]">
        <Markdown content={message.content} />
        {message.toolInvocations && message.toolInvocations.length > 0 && (
          <div className="space-y-2">
            {message.toolInvocations.map((toolInvocation, index) => (
              <ToolInvocationDisplay
                key={index}
                toolInvocation={toolInvocation}
                nexusState={nexusState}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
