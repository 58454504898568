import { LocationData } from '@/app/lib/types'

export const getClientLocation = (): Promise<LocationData> => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'))
    } else {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude } = position.coords
          try {
            const response = await fetch(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
            )
            const data = await response.json()
            resolve({
              city: data.city,
              state: data.principalSubdivision,
              country: data.countryName,
              latitude,
              longitude
            })
          } catch (error) {
            reject(error)
          }
        },
        error => {
          reject(error)
        }
      )
    }
  })
}

export const checkLocationRelevance = async (input: string) => {
  const response = await fetch('/api/check-location-relevance', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userInput: input })
  })
  const { isLocationRelevant } = await response.json()
  return isLocationRelevant
}

export const getLocationWithPermission = async (
  setIsWaitingForPermission: (waiting: boolean) => void
): Promise<LocationData | null> => {
  const permission = await navigator.permissions.query({ name: 'geolocation' })

  if (permission.state === 'granted') {
    try {
      return await getClientLocation()
    } catch (error) {
      console.error('Error getting location:', error)
      return null
    }
  } else if (permission.state === 'prompt') {
    return new Promise(resolve => {
      setIsWaitingForPermission(true)
      navigator.geolocation.getCurrentPosition(
        async position => {
          setIsWaitingForPermission(false)
          try {
            const locationData = await getClientLocation()
            resolve(locationData)
          } catch (error) {
            console.error('Error getting location:', error)
            resolve(null)
          }
        },
        error => {
          console.error('Geolocation error:', error)
          setIsWaitingForPermission(false)
          resolve(null)
        },
        { timeout: 3000 }
      )
    })
  } else {
    return null
  }
}
