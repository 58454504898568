import { useEffect, useState } from 'react'
import Image from 'next/image'

interface ThemeImageProps {
  lightSrc: string
  darkSrc: string
  alt: string
  width: number
  height: number
}

export default function ThemeImage({
  lightSrc,
  darkSrc,
  alt,
  width,
  height
}: ThemeImageProps) {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <>
      <Image
        src={lightSrc}
        alt={alt}
        width={width}
        height={height}
        className="block dark:hidden"
      />
      <Image
        src={darkSrc}
        alt={alt}
        width={width}
        height={height}
        className="hidden dark:block"
      />
    </>
  )
}
