import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'

/**
 * useSmartAutoscroll - A custom React hook for managing smart auto-scrolling behavior.
 *
 * This hook provides functionality to automatically scroll to the bottom of a container,
 * detect user scrolling, and manage scroll-related states. It's particularly useful for
 * chat interfaces or any UI where new content is dynamically added to the bottom.
 *
 * @returns {Object} An object containing the following properties and methods:
 *   @property {React.RefObject<HTMLDivElement>} scrollRef - Ref to be attached to the scrollable container.
 *   @property {React.RefObject<HTMLDivElement>} messagesEndRef - Ref to be attached to the element at the end of the messages.
 *   @property {boolean} isAtBottom - Indicates whether the scroll position is near the bottom.
 *   @property {boolean} isUserScrolling - Indicates whether the user is currently scrolling.
 *   @property {() => void} scrollToBottom - Function to scroll to the bottom of the container.
 *   @property {() => void} handleManualScroll - Function to handle manual scrolling to the bottom.
 *   @property {React.Dispatch<React.SetStateAction<boolean>>} setIsUserScrolling - Function to update the isUserScrolling state.
 *
 * Usage:
 * 1. Attach scrollRef to the scrollable container.
 * 2. Attach messagesEndRef to an element at the end of the message list.
 * 3. Use isAtBottom and isUserScrolling to determine when to auto-scroll.
 * 4. Call scrollToBottom when new content is added and auto-scroll is desired.
 * 5. Call handleManualScroll when a manual scroll to bottom is triggered (e.g., by a "scroll to bottom" button).
 * 6. Use setIsUserScrolling to update the scrolling state when user interactions occur.
 */
export function useSmartAutoscroll() {
  const scrollRef = useRef<HTMLDivElement>(null)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const [isUserScrolling, setIsUserScrolling] = useState(false)
  const [isTouching, setIsTouching] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [isAutoScrolling, setIsAutoScrolling] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768) // Adjust this breakpoint as needed
    }

    checkIfMobile()
    window.addEventListener('resize', checkIfMobile)

    return () => window.removeEventListener('resize', checkIfMobile)
  }, [])

  const scrollToBottom = useCallback(() => {
    if (isMobile) return // Don't auto-scroll on mobile
    setIsAutoScrolling(true)
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
    setTimeout(() => setIsAutoScrolling(false), 100) // Reset after scroll animation
  }, [isMobile])

  const handleScroll = useCallback(() => {
    if (scrollRef.current && !isAutoScrolling) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
      const scrollThreshold = Math.min(100, clientHeight * 0.1)
      const isNearBottom = scrollHeight - scrollTop - clientHeight < scrollThreshold

      setIsAtBottom(isNearBottom)

      // Detect if user is scrolling up
      if (scrollTop < lastScrollTop) {
        setIsUserScrolling(true)
      } else if (isNearBottom) {
        setIsUserScrolling(false)
      }

      setLastScrollTop(scrollTop)
    }
  }, [isAutoScrolling, lastScrollTop])

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 100), [handleScroll])

  useEffect(() => {
    const scrollElement = scrollRef.current
    if (scrollElement && !isMobile) {
      const onTouchStart = () => {
        setIsTouching(true)
        setIsUserScrolling(true) // Assume user is scrolling when they start touching
      }
      const onTouchEnd = () => {
        setIsTouching(false)
        // Delay the scroll check to allow the scroll to settle
        setTimeout(() => {
          handleScroll()
          if (isAtBottom) {
            setIsUserScrolling(false)
          }
        }, 100)
      }

      scrollElement.addEventListener('scroll', debouncedHandleScroll)
      scrollElement.addEventListener('touchstart', onTouchStart)
      scrollElement.addEventListener('touchend', onTouchEnd)

      return () => {
        scrollElement.removeEventListener('scroll', debouncedHandleScroll)
        scrollElement.removeEventListener('touchstart', onTouchStart)
        scrollElement.removeEventListener('touchend', onTouchEnd)
        debouncedHandleScroll.cancel()
      }
    }
  }, [debouncedHandleScroll, handleScroll, isAtBottom, isMobile])

  const handleManualScroll = useCallback(() => {
    setIsUserScrolling(false)
    setIsAtBottom(true)
    scrollToBottom()
  }, [scrollToBottom])

  return {
    scrollRef,
    messagesEndRef,
    isAtBottom,
    isUserScrolling,
    scrollToBottom,
    handleManualScroll,
    setIsUserScrolling,
    isMobile // Add this to the returned object
  }
}
