import { anthropic } from '@ai-sdk/anthropic'
import { google } from '@ai-sdk/google'
import { groq } from '@ai-sdk/groq'
import { createOpenAI, openai } from '@ai-sdk/openai'
import {
  experimental_createProviderRegistry as createProviderRegistry,
  experimental_customProvider as customProvider,
  Message
} from 'ai'

/** Represents the different response strategies available for language models. */
type ResponseStrategy = 'fast' | 'reasoning' | 'eloquent' | 'creative'

const openrouter = createOpenAI({
  baseURL: 'https://openrouter.ai/api/v1',
  apiKey: process.env.OPENROUTER_API_KEY
})

export const cora = customProvider({
  languageModels: {
    // Anthropic
    anthropic: anthropic('claude-3-5-sonnet-latest', {
      cacheControl: true
    }),
    'anthropic-fast': anthropic('claude-3-5-haiku-latest', { cacheControl: true }),
    // OpenAI
    openai: openrouter('openai/gpt-4o'),
    'openai-fast': openrouter('openai/gpt-4o-mini'),
    'openai-reasoning': openrouter('openai/o1-preview'),
    // Groq
    groq: groq('llama-3.1-70b-versatile'),
    'groq-fast': groq('llama-3.1-8b-instant'),

    // Response Strategies
    fast: groq('llama-3.1-8b-instant'),
    reasoning: openrouter('openai/gpt-4o'),
    eloquent: anthropic('claude-3-5-sonnet-latest', { cacheControl: true }),
    creative: anthropic('claude-3-5-sonnet-latest', { cacheControl: true }),

    // Capabilities
    attachments: anthropic('claude-3-5-sonnet-latest', { cacheControl: true }),
    structured: openrouter('openai/gpt-4o'),
    'structured-fast': openrouter('openai/gpt-4o-mini'),
    //'structured-fast': anthropic('claude-3-5-haiku-latest', { cacheControl: true }), // Too slow for concierge
    'structured-lightning': groq('llama3-groq-70b-8192-tool-use-preview'),

    default: anthropic('claude-3-5-sonnet-latest')
  }
})

export const registry = createProviderRegistry({
  // register provider with prefix and default setup:
  anthropic,
  cora,
  google,
  groq,
  openai,
  openrouter
})

export const providerLogos: { [key: string]: string } = {
  anthropic: '/img/anthropic-logo.svg',
  groq: '/img/groq-logo.svg',
  openai: '/img/openai-logo.svg',
  google: '/img/google-logo.svg'
}

// Helper function to parse model and provider from different formats
function parseModelAndProvider(input: string | any): { model: string; provider: string } {
  // Case 1: Input is a string like "cora:anthropic" or "openai/gpt-4"
  if (typeof input === 'string') {
    if (input.includes(':')) {
      // Handle cora:model format
      const [_, model] = input.split(':')
      return { model, provider: model.split('-')[0] }
    }
    if (input.includes('/')) {
      // Handle provider/model format (openrouter style)
      const [provider, model] = input.split('/')
      return { model, provider }
    }
    // Handle plain model name
    return {
      model: input,
      provider: input.split('-')[0]
    }
  }

  // Case 2: Input is an LLM object
  if (input.modelId) {
    if (input.modelId.includes('/')) {
      // Openrouter model
      const [provider, model] = input.modelId.split('/')
      return { model, provider }
    }
    if (input.config?.provider) {
      // format is $provider.chat or $provider.generative-ai
      const provider = input.config.provider.split('.')[0]
      return { model: input.modelId, provider }
    }
  }

  throw new Error('Unable to determine model info')
}

// Update getModelInfo to use the new parser
export function getModelInfo(llm: any): {
  humanProvider: string
  humanModel: string
  providerLogo: string
} {
  const { model, provider } = parseModelAndProvider(llm)

  const humanProviderNames: { [key: string]: string } = {
    anthropic: 'Anthropic',
    groq: 'Groq',
    openai: 'OpenAI',
    google: 'Google'
  }

  const humanModelNames: { [key: string]: string } = {
    // Anthropic
    'claude-instant-1.1': 'Claude (fast)',
    'claude-3-5-sonnet': 'Claude 3.5 Sonnet (thoughtful)',
    // OpenAI
    'gpt-4o': 'GPT 4o (thoughtful)',
    'gpt-4o-mini': 'GPT 4o mini (fast)',
    'o1-preview': 'O1 (reasoning)',
    // Google
    'gemini-1.5-pro': 'Gemini 1.5 Pro (thoughtful)',
    'gemini-1.5-flash': 'Gemini 1.5 Flash (fast)',
    // Groq models
    'llama-3.1-70b-versatile': 'LLaMA 3.1 70B (versatile)',
    'llama-3.1-8b-instant': 'LLaMA 3.1 8B (fast)',
    'gemma2-9b-it': 'Gemma 2 9B',
    'mixtral-8x7b-32768': 'Mixtral 8x7B'
  }

  const humanProvider =
    humanProviderNames[provider] || provider.charAt(0).toUpperCase() + provider.slice(1)

  const humanModel =
    humanModelNames[model] ||
    model
      .split('-')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')

  return {
    humanProvider,
    humanModel,
    providerLogo: providerLogos[provider] || ''
  }
}

export function getAvailableModels(): ModelOption[] {
  return [
    {
      value: 'auto',
      label: 'Smart Model Selection',
      logo: '/img/brain.svg'
    },
    {
      value: 'cora:anthropic',
      label: 'Claude Anthropic 3.5 (eloquent)',
      logo: '/img/anthropic-logo.svg'
    },
    {
      value: 'cora:openai',
      label: 'OpenAI GPT-4o (thoughtful)',
      logo: '/img/openai-logo.svg'
    },
    {
      value: 'cora:openai-reasoning',
      label: 'OpenAI O1 (reasoned, but slow)',
      logo: '/img/openai-logo.svg'
    },
    {
      value: 'cora:groq',
      label: 'Groq LLaMA 3.1 70b (fast)',
      logo: '/img/groq-logo.svg'
    }
  ]
}

// Type definitions
export type ModelOption = {
  value: string // e.g., 'auto' or 'cora:anthropic'
  label: string // e.g., 'Auto (Let Cora choose)' or 'Anthropic Claude 3.5'
  logo: string // e.g., '/img/cora-logo.svg'
}

export function getModelLabel(llm: any): string {
  const { humanModel, humanProvider } = getModelInfo(llm)
  return `${humanModel} via ${humanProvider}`
}

export function determineResponseModel(
  messages: Message[],
  responseStrategy: ResponseStrategy,
  selectedModel?: string
): string {
  // Check for attachments first
  const hasAttachments = messages.some(msg => msg.experimental_attachments?.length)

  if (hasAttachments) {
    // If using reasoning model with attachments, fall back to default
    if (selectedModel === 'cora:reasoning') {
      return 'cora:default'
    }
    return 'cora:attachments'
  }

  // If no model selected or auto, use response strategy
  if (!selectedModel || selectedModel === 'auto') {
    return `cora:${responseStrategy}`
  }

  // Use explicitly selected model
  return selectedModel
}
