import { UploadcareAuthSchema } from '@uploadcare/rest-client'

let uploadcareAuthSchemaPromise: Promise<UploadcareAuthSchema> | null = null

export function getUploadcareAuthSchema(): Promise<UploadcareAuthSchema> {
  if (!uploadcareAuthSchemaPromise) {
    uploadcareAuthSchemaPromise = new Promise((resolve, reject) => {
      const publicKey = process.env.UPLOADCARE_PUBLIC_KEY
      const secretKey = process.env.UPLOADCARE_PRIVATE_KEY

      if (!publicKey || !secretKey) {
        reject(
          new Error(
            'Uploadcare configuration is missing. Please check your environment variables.'
          )
        )
        return
      }

      const uploadcareConfig = {
        publicKey,
        secretKey
      }

      resolve(new UploadcareAuthSchema(uploadcareConfig))
    })
  }

  return uploadcareAuthSchemaPromise
}

// Client-side config with only the public key
export const uploadcareClientConfig = {
  publicKey: process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY
}
