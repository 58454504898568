// This tells Next.js that this file is a server-side file for server actions
'use server'

import { deleteFile } from '@uploadcare/rest-client'

import { logger } from '@/app/lib/logging'
import { getRedisClient } from '@/app/lib/redis'
import { getUserId } from '@/app/lib/server-utils'
import { Attachment } from '@/app/lib/types'
import { getUploadcareAuthSchema } from '@/app/lib/uploadcare'

/**
 * This file provides server-side functions for managing attachments from Uploadcare in Redis.
 * It includes functionality to:
 * - Save attachments: Store Uploadcare file data in Redis
 * - Retrieve attachments: Fetch all attachments for a user
 * - Remove attachments: Delete a specific attachment
 *

 * Redis Key Structure:
 *
 * attachment:<uuid>           -> Stores individual attachment data
 * |
 * attachment_list:<userId>    -> Sorted set of attachment keys
 *   |
 *   +-- attachment:<uuid1>    (score: timestamp)
 *   +-- attachment:<uuid2>    (score: timestamp)
 *   +-- ...
 */

export async function saveAttachment(
  attachment: Attachment,
  providedUserId?: string | undefined
) {
  try {
    const userId = await getUserId(providedUserId)
    if (!userId) {
      throw new Error('User ID is required for attachments')
    }

    const client = await getRedisClient()

    if (!attachment.uuid) {
      throw new Error('Attachment must have a uuid')
    }

    const attachmentKey = `attachment:${attachment.uuid}`
    const serializedAttachment = JSON.stringify({ ...attachment, userId })

    const multi = client.multi()
    multi.set(attachmentKey, serializedAttachment)
    multi.zAdd(`attachment_list:${userId}`, {
      score: Date.now(),
      value: attachmentKey
    })

    await multi.exec()

    logger.info('Attachment saved successfully', { attachmentId: attachment.uuid, userId })
    return { success: true }
  } catch (error) {
    logger.error('Failed to save attachment:', error)
    throw new Error("We couldn't save your attachment right now. Please try again later.")
  }
}

export async function getAttachments(
  providedUserId?: string | undefined
): Promise<Attachment[]> {
  try {
    const userId = await getUserId(providedUserId)
    if (!userId) {
      logger.warn('No user ID found, returning empty attachment list')
      return []
    }

    const client = await getRedisClient()
    const attachmentKeys = await client.zRange(`attachment_list:${userId}`, 0, -1, {
      REV: true
    })

    const attachments: Attachment[] = []
    for (const key of attachmentKeys) {
      const attachmentData = await client.get(key)
      if (attachmentData) {
        attachments.push(JSON.parse(attachmentData))
      }
    }

    logger.debug('Attachments fetched successfully', { userId, count: attachments.length })
    return attachments
  } catch (error) {
    logger.error('Failed to fetch attachments:', error)
    throw new Error("We couldn't fetch your attachments right now. Please try again later.")
  }
}

export async function deleteAttachment(uuid: string, providedUserId?: string | undefined) {
  try {
    const userId = await getUserId(providedUserId)
    if (!userId) {
      throw new Error('User ID is required to remove attachments')
    }

    const client = await getRedisClient()

    const attachment = await client.get(`attachment:${uuid}`)
    if (!attachment) {
      throw new Error('Attachment not found')
    }

    const parsedAttachment = JSON.parse(attachment)
    if (parsedAttachment.userId !== userId) {
      throw new Error('Unauthorized')
    }

    // Delete from Uploadcare
    try {
      const uploadcareAuthSchema = await getUploadcareAuthSchema()
      await deleteFile({ uuid }, { authSchema: uploadcareAuthSchema })
      logger.info('Attachment deleted from Uploadcare', { uuid })
    } catch (uploadcareError) {
      logger.error('Failed to delete attachment from Uploadcare:', uploadcareError)
      throw new Error("We couldn't remove that attachment right now. Please try again later.")
    }

    // Delete from Redis
    try {
      const multi = client.multi()
      multi.del(`attachment:${uuid}`)
      multi.zRem(`attachment_list:${userId}`, `attachment:${uuid}`)

      await multi.exec()
      logger.info('Attachment removed from redis successfully', { uuid, userId })
      return { success: true }
    } catch (error) {
      logger.error('Failed to remove attachment from redis:', error)
      throw new Error("We couldn't remove that attachment right now. Please try again later.")
    }
  } catch (error) {
    if (error instanceof Error) {
      throw error // Re-throw the original error
    } else {
      throw new Error("We couldn't remove that attachment right now. Please try again later.")
    }
  }
}

export async function getAttachment(uuid: string): Promise<Attachment | null> {
  try {
    const client = await getRedisClient()

    const attachmentKey = `attachment:${uuid}`
    const attachment = await client.get(attachmentKey)

    if (!attachment) {
      return null
    }

    return JSON.parse(attachment)
  } catch (error) {
    logger.error('Failed to get attachment:', error)
    throw new Error("We couldn't fetch that attachment right now. Please try again later.")
  }
}
